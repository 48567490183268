import React, { useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Dashboard from "../pages/admin/dashboard";
import Header from "../layout/Header";
import Footer from "../layout/footer";
import SideBar from "../layout/SideBar";
import Service from "../pages/admin/Service";
import ManageCouples from "../pages/admin/ManageCouples";
import ManageRetreat from "../pages/admin/Retreats/ManageRetreat";
import Talks from "../pages/admin/Talks";
import Roles from "../pages/admin/Roles";
import SendEmail from "../pages/admin/SendEmail";
import MyProfile from "../pages/admin/MyProfile";
import Perish from "../pages/admin/Perish";
import SearchData from "../pages/admin/SearchData";
import Help from "../pages/admin/Help";
import RetretRoaster from "../pages/admin/Retreats/RetreatRoaster";
import QuickReport from "../pages/admin/QuickReport";
import CoupleUpdate from "../pages/common/coupleUpdate";
import ThankYou from "../pages/common/thankYou";
import LogReport from "../pages/admin/LogReport";
import AccessSuperAdmin from "../pages/admin/AccessSuperAdmin";
import RetreatRegistrations from "../pages/admin/retreatRegistrations";
import { RegistrationRetreat } from "../pages/auth/registrationRetreat";
import Directory from "../pages/auth/Directory";
import Program from "../pages/auth/Program";
import { CreateRetreat } from "../pages/admin/Retreats/CreateRetreat";
import { CreateProgram } from "../pages/admin/Retreats/CreateProgram";
import { CreateDirectory } from "../pages/admin/Retreats/CreateDirectory";
import RetreatBackUp from "../pages/admin/Retreats/RetreatBackUp";
import { UpdateCouple } from "../pages/couple/updateCouple";
import { Cookies } from "react-cookie";
import AdminDashboard from "../pages/admin/AdminDashboard";
import ParticipatedInRetreats from "../pages/couple/participatedInRetreats";
import CreateOuterRetreat from "../pages/auth/CreateOuterRetreat";
import NewRetreats from "../pages/admin/Retreats/NewRetreats";

const AdminRouter = ({ logoutUser = null }) => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  const commonRotes = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/quickReport", element: <QuickReport></QuickReport> },
    { path: "/help", element: <Help /> },
    { path: "/search_data", element: <SearchData /> },
    { path: "/service", element: <Service /> },
    { path: "/talks", element: <Talks /> },
    { path: "/roles", element: <Roles /> },
    { path: "/sendEmail", element: <SendEmail /> },
    { path: "/manageCouples", element: <ManageCouples /> },
    { path: "/manageRetreat", element: <ManageRetreat /> },
    { path: "/my-profile", element: <MyProfile /> },
    { path: "/parish", element: <Perish /> },
    { path: "/retreatRoster", element: <RetretRoaster /> },
    { path: "/couple-update/:coupleId", element: <CoupleUpdate /> },
    { path: "/thankyou", element: <ThankYou /> },
    { path: "/logreport", element: <LogReport /> },
    { path: "/Access-SuperAdmin", element: <AccessSuperAdmin /> },
    { path: "/registration-retreat/:id", element: <RegistrationRetreat /> },
    { path: "/register-new-retreat", element: <CreateOuterRetreat /> },
    { path: "/directory/:id", element: <Directory /> },
    { path: "/program/:id", element: <Program /> },
    { path: "/new-retreats", element: <NewRetreats /> },
    {
      path: "/retreat-registrations",
      element: <RetreatRegistrations />,
    },

    {
      path: "/create-retreat",
      element: <CreateRetreat />,
    },
    {
      path: "/create-retreat/:id",
      element: <CreateRetreat />,
    },
    {
      path: "/create-program/:id",
      element: <CreateProgram />,
    },

    {
      path: "/create-directory/:id",
      element: <CreateDirectory />,
    },

    {
      path: "/retreat-backup",
      element: <RetreatBackUp />,
    },

    { path: "/participated-in-retreats", element: <ParticipatedInRetreats /> },

    {
      path: "/admin-dashboard",
      element: <AdminDashboard />,
    },

    { path: "/update-couple", element: <UpdateCouple /> },
  ];

  if (
    (userData && userData.userRole === "SUBADMIN") ||
    (userData.userRole === "ADMIN" && userData.isDisabled === 1)
  ) {
    commonRotes.push({ path: "/*", element: <Navigate to="/update-couple" /> });
  } else {
    commonRotes.push({ path: "/*", element: <Navigate to="/dashboard" /> });
  }

  const route = useRoutes(commonRotes);

  const [active, setActive] = useState("");
  const RightNav = () => {
    if (active === "active") {
      setActive("");
    } else {
      setActive("active");
    }
  };
  const [LeftPanel, setLeftPanel] = useState("");
  const LeftNav = () => {
    if (LeftPanel === "sidebar-icon-only") {
      setLeftPanel("");
    } else {
      setLeftPanel("sidebar-icon-only");
    }
  };
  let pathName = route;
  if (
    pathName.props.match.route.path === "/couple-update/:coupleId" ||
    pathName.props.match.route.path === "/thankyou" ||
    pathName.props.match.route.path === "/registration-retreat/:id" ||
    pathName.props.match.route.path === "/directory/:id" ||
    pathName.props.match.route.path === "/program/:id" ||
    pathName.props.match.route.path === "/register-new-retreat"
  ) {
    return <>{route}</>;
  }
  return (
    <div className={`${LeftPanel}`}>
      <div className="container-scroller" id="container-scroller">
        <Header logoutUser={logoutUser} RightNav={RightNav} LeftNav={LeftNav} />
        <div className="container-fluid page-body-wrapper">
          <SideBar active={active} RightNav={RightNav} />
          <div className="main-panel">
            <div className="content-wrapper retreats">{route}</div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminRouter;
