import React, { useState, useEffect } from "react";
import RetreatApi from "../api/retreatApi";
import { toast } from "react-toastify";
import AlertModal from "../components/AlertModel";
import { Form } from "react-bootstrap";

const Confirmations = ({
  retreatHeading,
  setSelectedRetreat,
  setRetreatHeading,
  handleConfirmStatus,
  setIncommingRetreats,
  userData,
}) => {
  let retreatApi = new RetreatApi();
  const [confirmDir, setConfirmDir] = useState(false);
  const [confirmProg, setConfirmProg] = useState(false);
  const [confirmRoastData, setConfirmRoastData] = useState(false);
  const [confirmation, setConfirmation] = useState([]);

  const confirmAlert = (retreatData, item) => {
    if (item === "Directory") {
      setConfirmDir(true);
      setConfirmation([
        { retreatData: retreatData, item: "Directory", userData: userData },
      ]);
    } else if (item === "Program") {
      setConfirmProg(true);
      setConfirmation([
        { retreatData: retreatData, item: "Program", userData: userData },
      ]);
    } else {
      setConfirmRoastData(true);
      setConfirmation([
        { retreatData: retreatData, item: "roasterData", userData: userData },
      ]);
    }
  };

  const closeConfirmAlert = () => {
    setConfirmDir(false);
    setConfirmProg(false);
    setConfirmRoastData(false);
    setConfirmation([]);
  };

  const handleConfirmation = () => {
    retreatApi
      .handleConfirmation(confirmation)
      .then(async (res) => {
        if (res.success) {
          toast.success(res.message);
          closeConfirmAlert();
          handleConfirmStatus();
          setSelectedRetreat(res.data.id);
          setRetreatHeading(res.data);
          setIncommingRetreats((prevRetreats) =>
            prevRetreats.map((item) =>
              item.id === res.data.id ? { ...item, ...res.data } : item
            )
          );
        } else {
          toast.error(res.message);
          closeConfirmAlert();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggles = [
    {
      name: "Directory",
      id: "directory",
      label: retreatHeading?.confirmation?.[0]?.Directory
        ? "Confirmed Directory"
        : "Confirm Directory",
    },
    {
      name: "Program",
      id: "program",
      label: retreatHeading?.confirmation?.[1]?.Program
        ? "Confirmed Program"
        : "Confirm Program",
    },
    {
      name: "roasterData",
      id: "roasterData",
      label: retreatHeading?.confirmation?.[2]?.roasterData
        ? "Confirmed Roster Data"
        : "Confirm Roster Data",
    },
  ];

  return (
    <div className="circles">
      <div className="circles2">
        {toggles.map((toggle) => (
          <div className="d-flex leftSideButtons" key={toggle.id}>
            <Form.Check
              disabled={retreatHeading?.freeze === "FREEZE"}
              className={`switchSize confirm-toggle ${
                retreatHeading?.confirmation?.[toggles.indexOf(toggle)]?.[
                  toggle.name
                ]
                  ? "toggle-on"
                  : "toggle-off-confirm"
              }`}
              type="switch"
              name={toggle.name}
              value={
                retreatHeading?.confirmation?.[toggles.indexOf(toggle)]?.[
                  toggle.name
                ] || false
              }
              checked={
                retreatHeading?.confirmation?.[toggles.indexOf(toggle)]?.[
                  toggle.name
                ] || false
              }
              onChange={() => confirmAlert(retreatHeading, toggle.name)}
              id={toggle.id}
            />
            <h6
              className={`default ${
                retreatHeading?.confirmation?.[toggles.indexOf(toggle)]?.[
                  toggle.name
                ]
                  ? "confirmLabel"
                  : "noConfirmLabel"
              }`}
            >
              <a>{toggle.label}</a>
            </h6>
          </div>
        ))}
      </div>

      {confirmDir ? (
        <AlertModal
          show={confirmDir}
          handleClose={closeConfirmAlert}
          message={`Are you sure you want to confirm the directory for this retreat?`}
          actionFunction={handleConfirmation}
        />
      ) : null}

      {confirmProg ? (
        <AlertModal
          show={confirmProg}
          handleClose={closeConfirmAlert}
          message={`Are you sure you want to confirm the Program for this retreat?`}
          actionFunction={handleConfirmation}
        />
      ) : null}

      {confirmRoastData ? (
        <AlertModal
          show={confirmRoastData}
          handleClose={closeConfirmAlert}
          message={`Are you sure you want to confirm the Roster Data for this retreat?`}
          actionFunction={handleConfirmation}
        />
      ) : null}
    </div>
  );
};

export default Confirmations;
