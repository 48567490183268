import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import AdminApi from "../../../api/admin";
import RetreatApi from "../../../api/retreatApi";
import Helpers from "../../../components/Helpers";
import DeleteModel from "../../../components/DeleteModel";
import {
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form,
  Modal,
  Popover,
} from "react-bootstrap";
import CustomDataTable from "../../../components/CustomDataTable";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import SunEditor from "suneditor-react";
import TimePicker from "../../../components/TimePicker";
import uploadRetreat from "../../../assets/images/uploadRetreat.png";

const NewRetreats = () => {
  const [total, setTotal] = useState(20);
  const [show, setShow] = useState(false);
  const [searchKey, setSearchKey] = useState(""); //for searching
  const [LoadData, setLoadData] = useState(false);
  const [tempRetreats, setTempRetreats] = useState([]);
  const [tempRetreatDetail, setTempRetreatDetail] = useState([]);
  const [showRegModal, setShowREgModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [missionStatement, setMissionStatement] = useState("");
  const [description, setDescription] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isTempUpload1, setIsTempUpload1] = useState(false);
  const [applyLoader, setApplyLoader] = useState(false);
  const [fromErrorMsg, setFromErrorMsg] = useState(false);
  const [check, setCheck] = useState("");
  const [toErrorMsg, setToErrorMsg] = useState(false);
  const [greenTick, setGreenTick] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [Pass, setPass] = useState(false);
  const [timeDate, setTimeDate] = useState({
    date: new Date(),
    from: "00 : 00",
    to: "00 : 00",
  });

  let adminApi = new AdminApi();
  let retreatApi = new RetreatApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  useEffect(() => {
    getAllTempRetreats();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on New Retreats page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const editCouple = async (row) => {
    setData(row);
    setDescription(row.description);
    setEmailMsg(row.mail_msg);
    setMissionStatement(row.mission_statement);
  };

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <button
                  className="font-size"
                  onClick={() => {
                    editCouple(row);
                    setShowREgModal(true);
                  }}
                >
                  <i
                    className="mdi mdi-pencil-box text-lg text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button
                  className="font-size action-icons"
                  onClick={() => handleShow(row)}
                >
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
                {/* <a
                  href={`/registration-retreat/${row.id}`}
                  className="font-size action-icons"
                  target="_blank"
                >
                  <i className="mdi mdi-eye" title="View"></i>
                </a> */}
              </>
            ),
            reorder: true,
            width: "100px",
          },
        ]
      : []),
    {
      id: "title",
      name: "Retreat Title",
      selector: (row) => (
        <>
          <div>{row.title}</div>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              row.dateFrom === row.dateTo
                ? moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM-DD-YYYY")
                : moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM DD") +
                    " - " +
                    moment(row && row.dateTo)
                      .tz("America/New_York")
                      .format("MMM DD") +
                    ", " +
                    moment(row && row.dateFrom)
                      .tz("America/New_York")
                      .format("YYYY")
            )}
          >
            {row.dateFrom === row.dateTo ? (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD-YYYY")}
              </span>
            ) : (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD") +
                  " - " +
                  moment(row.dateTo).tz("America/New_York").format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("YYYY")}
              </span>
            )}
          </OverlayTrigger>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "language",
      name: "Language",
      selector: (row) =>
        row.language.charAt(0).toUpperCase() + row.language.slice(1),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.language.charAt(0).toUpperCase() + row.language.slice(1)
          )}
        >
          <span>
            {row.language.charAt(0).toUpperCase() + row.language.slice(1)}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "location",
      name: "Location",
      selector: (row) =>
        row.location.charAt(0).toUpperCase() + row.location.slice(1),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.location.charAt(0).toUpperCase() + row.location.slice(1)
          )}
        >
          <span>
            {row.location.charAt(0).toUpperCase() + row.location.slice(1)}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "fromToDate",
      name: "Retreat Dates",
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.dateFrom === row.dateTo
              ? moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD-YYYY")
              : moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM DD") +
                  " - " +
                  moment(row && row.dateTo)
                    .tz("America/New_York")
                    .format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("YYYY")
          )}
        >
          {row.dateFrom === row.dateTo ? (
            <span>
              {moment(row && row.dateFrom)
                .tz("America/New_York")
                .format("MMM-DD-YYYY")}
            </span>
          ) : (
            <span>
              {moment(row && row.dateFrom)
                .tz("America/New_York")
                .format("MMM-DD") +
                " - " +
                moment(row.dateTo).tz("America/New_York").format("MMM DD") +
                ", " +
                moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("YYYY")}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "140px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "contact_name",
      name: "Contact Name",
      selector: (row) => row.contact_name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.contact_name)}
        >
          <span>{row.contact_name}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "contact_name",
      name: "Contact Email",
      selector: (row) => row.contact_email,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.contact_email)}
        >
          <span>{row.contact_email}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "contact_mobile",
      name: "Contact Mobile",
      selector: (row) => row.contact_mobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.contact_mobile)}
        >
          <span>{row.contact_mobile}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row && row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
  ];

  const formattedDate = (date) => {
    return moment(date).tz("America/New_York").format("YYYY-MM-DD");
  };

  const getAllTempRetreats = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    setIsTempUpload1(false);
    setLoadData(true);
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    await retreatApi
      .getAllTempRetreats(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setTotal(totalRecords);
          const parsedData = res.data.map((item) => {
            const { retreat_team, schedule, ...rest } = item;
            const parsedSchedule = schedule ? JSON.parse(schedule) : [];
            const parsedRetreat_team = retreat_team
              ? JSON.parse(retreat_team)
              : [];
            return {
              ...rest,
              schedule: parsedSchedule.map((entry) => ({
                date: new Date(entry.date),
                from: entry.from,
                to: entry.to,
              })),
              retreat_team: parsedRetreat_team,
            };
          });
          setLoadData(false);
          toast.success(res.msg);
          setTempRetreats(parsedData);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
      });
  };

  const handleShow = (item) => {
    setTempRetreatDetail(item);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setTempRetreatDetail([]);
    getAllTempRetreats();
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getAllTempRetreats(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getAllTempRetreats(1, 20, event.target.value);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const deleteTempRetreat = async () => {
    await retreatApi
      .deleteTempRetreat(tempRetreatDetail)
      .then(async (res) => {
        if (res.success) {
          handleClose();
          setShowREgModal(false);
          toast.success(res.msg);
          getAllTempRetreats();
        } else {
          handleClose();
          toast.error(res.msg);
          getAllTempRetreats();
          setShowREgModal(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const CloseCompose = () => {
    setValidated(false);
    setShowREgModal(false);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    if (e.target.name === "language") {
      let missionData = "";
      if (e.target.value === "spanish") {
        d.mission_statement = `"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."`;
        setMissionStatement(
          `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`
        );
        missionData = `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`;
      } else {
        d.mission_statement = `"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."`;
        setMissionStatement(
          `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`
        );
        missionData = `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`;
      }
      i18n.changeLanguage(e.target.value);
    }
    if (name == "image1") {
      d.image1 = e.target.files[0];
      toast.success("The profile image has been successfully added.");
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const upload = () => {
    document.getElementById("retreatImageUpload").click();
    setIsTempUpload1(true);
  };

  const remove = () => {
    let d = { ...data };
    d.image1 = "";
    setData(d);
    setIsTempUpload1(false);
  };

  const handleTimeChange = (name, value, index) => {
    const newData = { ...data };

    // Ensure the schedule array has an entry at the specified index
    if (!newData.schedule[index]) {
      newData.schedule[index] = {};
    }

    if (name.includes("date")) {
      // Adjust the date for the specified timezone
      const adjustedDate = moment(value)
        .tz("America/New_York", true)
        .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
      // Set the date property for the schedule entry at the given index
      newData.schedule[index].date = adjustedDate;

      // Sort the schedule by date
      // newData.schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Update day name based on the adjusted date and language
      newData.schedule.forEach((item) => {
        item.dayName = getDayName(item.date, newData.language);
      });
    } else if (name.includes("from")) {
      // Check if "From" and "To" times are the same
      if (newData.schedule[index].to && newData.schedule[index].to === value) {
        setCheck(`${index}`);
      } else {
        setCheck("");
        newData.schedule[index].from = value;
      }
    } else if (name.includes("to")) {
      // Check if "To" and "From" times are the same
      if (
        newData.schedule[index].from &&
        newData.schedule[index].from === value
      ) {
        setCheck(`${index}`);
      } else {
        setCheck("");
        newData.schedule[index].to = value;
      }
    }

    setData(newData);
  };

  function getDayName(dateString, language) {
    const dateInUSTimezone = moment(dateString).tz("America/New_York");
    let days = [];
    if (language === "spanish") {
      days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
    } else {
      days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    return days[dateInUSTimezone.day()];
  }

  const handleRetreatTeam = (e, couple) => {
    const { name, value } = e.target;
    setData((prevData) => {
      // Find the index of the couple role in the retreat_team array
      const updatedTeam = prevData.retreat_team.map((member) =>
        member.role === couple ? { ...member, [name]: value } : member
      );
      return {
        ...prevData,
        retreat_team: updatedTeam,
      };
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      setIsTempUpload1(false);
      setApplyLoader(true);
      let data1 = new FormData();
      if (data && data.image1 && data.image1.name) {
        data1.append("file1", data.image1);
        delete data.image1;
      }
      let stateRef = { ...data };
      stateRef.mission_statement = missionStatement;
      stateRef.description = description;
      stateRef.mail_msg = emailMsg;
      if (stateRef.schedule && data.schedule.length) {
        data1.append("fromData", JSON.stringify(stateRef));
        retreatApi
          .approveRetreat(data1)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              getAllTempRetreats();
              setApplyLoader(false);
              setShowREgModal(false);
            } else {
              setApplyLoader(false);
              toast.error(res.msg);
              setShowREgModal(false);
            }
          })
          .catch((error) => {
            setApplyLoader(false);
            toast.error(error);
          });
      } else {
        toast.error("Atleast one time schedule is required!");
      }
    }
    setValidated(true);
  };

  const renderModel = () => {
    return (
      <div className="tab_headers">
        <Modal
          show={showRegModal}
          onHide={() => {
            CloseCompose();
          }}
          size="lg"
        >
          <Modal.Header
            closeButton={() => {
              CloseCompose();
            }}
            className="pb-5 px-4 px-sm-5"
          >
            <h4> New Retreat Detail</h4>
          </Modal.Header>
          <div className="row w-100 mx-0">
            <div className="col-lg-12 mx-auto">
              <div className="auth-form-light text-left main-register pb-5 px-4 px-sm-5">
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample"
                >
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter the Parish Name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="parish"
                          id="parish"
                          placeholder="Parish"
                          value={data.parish}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Parish is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Select Language <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          size="sm"
                          type="text"
                          name="language"
                          id="language"
                          placeholder="Language"
                          value={data.language}
                          onChange={(e) => handleChanges(e)}
                        >
                          <option value="">Select</option>
                          <option value="english">English</option>
                          <option value="spanish">Spanish</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Language is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Location <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="location"
                          id="location"
                          placeholder="Location"
                          value={data.location}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Location is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Retreat Theme</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="heading"
                          id="heading"
                          placeholder="Retreat Theme"
                          value={data.heading}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Retreat Theme is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        {data.image1 == "" || data.image1 == undefined ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={uploadRetreat} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload1 ? (
                              <>
                                <img src={URL.createObjectURL(data?.image1)} />
                              </>
                            ) : (
                              <>
                                <img src={data?.image1} />
                              </>
                            )}
                          </div>
                        )}{" "}
                        <div className="text-center mt-3">
                          {data.image1 == "" || data.image1 == undefined ? (
                            <>
                              <input
                                name="image1"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="retreatImageUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 text-center"
                                onClick={(e) => {
                                  upload();
                                }}
                              >
                                Upload Retreat Image
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                remove();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Friday</h4>
                        </Col>

                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[0] &&
                                data.schedule[0].date &&
                                data.schedule[0].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[0].date)
                                    ).toDate()
                                  : null
                              }
                              name="date0"
                              onChange={(date) =>
                                handleTimeChange("date0", date, 0)
                              }
                              filterDate={(date) => date.getDay() === 5} // 5 represents Friday
                            />

                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from0", time, 0)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[0] &&
                                data.schedule[0].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to0", time, 0)
                              }
                              value={
                                data && data.schedule[0] && data.schedule[0].to
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "0" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Saturday</h4>
                        </Col>

                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[1] &&
                                data.schedule[1].date &&
                                data.schedule[1].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[1].date)
                                    ).toDate()
                                  : null
                              }
                              name="date1"
                              onChange={(date) =>
                                handleTimeChange("date1", date, 1)
                              }
                              filterDate={(date) => date.getDay() === 6}
                            />
                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from1", time, 1)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[1] &&
                                data.schedule[1].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to1", time, 1)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data && data.schedule[1] && data.schedule[1].to
                              }
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "1" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>

                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Sunday</h4>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[2] &&
                                data.schedule[2].date &&
                                data.schedule[2].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[2].date)
                                    ).toDate()
                                  : null
                              }
                              name="date2"
                              onChange={(date) =>
                                handleTimeChange("date2", date, 2)
                              }
                              filterDate={(date) => date.getDay() === 0}
                            />
                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from2", time, 2)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[2] &&
                                data.schedule[2].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to2", time, 2)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data && data.schedule[2] && data.schedule[2].to
                              }
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "2" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>
                    {/* <Col lg={12} md={12} xs={12}>
                      <Form.Group
                        className="form-group col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Mission Statement</Form.Label>
                        <SunEditor
                          id="outlined-adornment-quote"
                          // autoFocus={false}
                          type="text"
                          onChange={(e) => setMissionStatement(e)}
                          setContents={missionStatement}
                          name="mission_statement"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Mission Statement is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col> */}

                    <Col lg={12} md={12} xs={12}>
                      {" "}
                      <Form.Group
                        className="form-group col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Description</Form.Label>
                        <SunEditor
                          id="outlined-adornment-quote"
                          type="text"
                          onChange={(e) => setDescription(e)}
                          setContents={description}
                          name="description"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Description is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={12} md={12} xs={12}>
                      <Form.Group
                        className="form-group col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Email Message</Form.Label>
                        <SunEditor
                          id="outlined-adornment-quote"
                          type="text"
                          onChange={(e) => setEmailMsg(e)}
                          setContents={emailMsg}
                          name="mail_msg"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Email message is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={4} xs={12} className="border-right">
                      <h4>Lead Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.hisFirstName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.hisLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.hisEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="Her First Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.herFirstName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.herLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "lead_couple"
                                )?.herEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>

                    <Col lg={4} md={4} xs={12} className="border-right">
                      <h4>Co-Lead Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.hisFirstName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.hisLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.hisEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="Her First Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.herFirstName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.herLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "co_lead_couple"
                                )?.herEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <h4>Mentor Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.hisFirstName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.hisLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.hisEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.herFirstName) ||
                              ""
                            }
                            placeholder="Her First Name"
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.herLastName) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              (data &&
                                data.retreat_team &&
                                data.retreat_team.find(
                                  (member) => member.role === "mentor_couple"
                                )?.herEmail) ||
                              ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>
                  </Row>
                  <hr></hr>

                  <Row>
                    <h3 className="mt-3 mb-3">Contact Information</h3>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="contact_name"
                          id="contact_name"
                          placeholder="Contact Name"
                          value={data.contact_name}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Name is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="contact_email"
                          name="contact_email"
                          id="contact_email"
                          placeholder="Contact Email"
                          value={data.contact_email} // corrected to data.email
                          onChange={(e) => handleChanges(e)} // handle the onChange event
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Email is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Mobile Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="tel"
                          name="contact_mobile"
                          id="contact_mobile"
                          placeholder="Contact Mobile"
                          value={data.contact_mobile}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Validate allowed characters dynamically
                            if (/^[0-9()\- ]*$/.test(value)) {
                              handleChanges(e); // Update state only for valid input
                            }
                          }}
                          pattern="^[0-9()\- ]*$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Mobile is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Col lg={12} md={12} xs={12} className="text-center mt-5">
                    <Modal.Footer>
                      <Button variant="primary" type="submit">
                        Approve
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleShow(data);
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          CloseCompose();
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Col>
                </Form>
              </div>{" "}
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-lg-12 d-flex flex-column mt-1">
        <div className="card  custom-card">
          <div className="card-body">
            <div className=" mb-3 d-sm-flex justify-content-between align-items-start Couple_heading">
              <h4 className="card-title">New Retreats</h4>
              <div className="d-flex">
                <div className="search">
                  <div className="input-group setSearch">
                    <input
                      onChange={SearchData}
                      type="text"
                      className="form-control searchData"
                      placeholder="Search..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-sm btn-primary" type="button">
                        <i className="icon-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomDataTable
              data={tempRetreats}
              countPerPage={20}
              isServerSide={true}
              pagination={true}
              sortServer={true}
              total={total}
              columns={columns}
              dataFetchApi={getAllTempRetreats}
              pointerOnHover={true}
              highlightOnHover={true}
              // fixedHeader={true}
            />
            {/* } */}
          </div>
        </div>
      </div>
      {show ? (
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={deleteTempRetreat}
        ></DeleteModel>
      ) : null}
      {showRegModal ? renderModel() : null}
    </div>
  );
};

export default NewRetreats;
