import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  Tab,
  Tabs,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Container,
  ListGroup,
} from "react-bootstrap";
import AdminApi from "../../../api/admin";
import AuthApi from "../../../api/authApi";
import { toast } from "react-toastify";
import CustomDataTable from "../../../components/CustomDataTable";
import Helpers from "../../../components/Helpers";
import DeleteModel from "../../../components/DeleteModel";
import { Cookies } from "react-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RetreatApi from "../../../api/retreatApi";
import uploadRetreat from "../../../assets/images/uploadRetreat.png";
import RetreatRoastApi from "../../../api/retreatRoasterApi";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import ImagePlaceHolder from "../../../assets/images/image-placeholder.png";
import DirectoryApi from "../../../api/directoryApi";
import ProgramApi from "../../../api/programApi";
import Profile from "../../../assets/images/couple-default.png";
import fbIcon from "../../../assets/images/facebook-logo.webp";
import QrDummy from "../../../assets/images/qrScanner.jpg";
import AlertDelete from "../../../components/AlertDelete";

import moment from "moment-timezone";
import DirectoryModel from "./DirectoryModel";
import ProgramModel from "./ProgramModel";
import FileUploadModal from "../../../components/FileUploadModal";
import AlertModal from "../../../components/AlertModel";

const ManageRetreat = () => {
  const [showRegModal, setShowREgModal] = useState(false);
  const [total, setTotal] = useState(20);
  const [searchKey, setSearchKey] = useState(""); //for searching
  const [show, setShow] = useState(false);
  const [icon_id, setIcon_id] = useState("");
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [perishDataList, setPerishDataList] = useState([]);
  const [allRetreatData, setAllRetreatData] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const [directory, setDirectory] = useState([]);
  const [program, setProgram] = useState([]);
  const [retreatDetail, setRetreatDetail] = useState([]);
  const [deleteList, setDeleteList] = useState(null);
  const [contactData, setContactData] = useState(false);
  const [confirmedData, setConfirmedData] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false); // Controls modal visibility
  const [selectedRowId, setSelectedRowId] = useState(null); // Stores the ID of the selected row
  const [fileType, setFileType] = useState("");
  const [existingFileUrl, setExistingFileUrl] = useState("");
  const [uploadModalTitle, setUploadModalTitle] = useState("");

  const [alertData, setAlertData] = useState([]);
  const [showFreeze, setShowFreeze] = useState(false);
  let adminApi = new AdminApi();
  let retreatApi = new RetreatApi();
  let authApi = new AuthApi();
  let directoryApi = new DirectoryApi();
  let programApi = new ProgramApi();
  let navigate = useNavigate();

  const [data, setData] = useState({
    id: "",
    retreatment: "",
    title: "",
    parish: "",
    language: "",
    location: "",
    schedule: [],
    image1: "",
    image2: "",
    heading: "",
    mission_statement:
      "The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered bond between married couples in our parish and community.",
    description: "",
  });
  const [timeDate, setTimeDate] = useState({
    date: "",
    from: "00:00",
    to: "00:00",
  });
  const [newTitle, setNewTitle] = useState({
    parish: "",
    language: "",
    location: "",
  });

  useEffect(() => {
    getAllRetreats();
    getPerishList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Manage Retreats page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUploadProgram = (retreat) => {
    setSelectedRowId(retreat.id);
    setUploadModalTitle(retreat.title);
    setFileType("programFile");
    setShowUploadModal(true);
    setExistingFileUrl(retreat.programFile);
  };

  const handleUploadDirectory = (retreat) => {
    setSelectedRowId(retreat.id);
    setUploadModalTitle(retreat.title);
    setFileType("directoryFile");
    setShowUploadModal(true); // Show the upload modal
    setExistingFileUrl(retreat.directoryFile);
  };

  // Handle closing the modal
  const handleUploadModalClose = () => {
    setShowUploadModal(false);
    setSelectedRowId(null); // Reset the row ID(Retreat Id)
    setFileType("");
    setExistingFileUrl("");
    setUploadModalTitle("");
  };

  // Callback to trigger after successful upload
  const handleUploadSuccess = () => {
    getAllRetreats();
  };

  const handleDeleteFile = () => {
    setExistingFileUrl(null); // Update the UI after deletion
    getAllRetreats(); // Refresh data
  };

  const getAllRetreats = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false,
    coupleId
  ) => {
    setAllRetreatData([]);
    setLoadData(true);
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    d.coupleId = coupleId;
    await retreatApi
      .getAllRetreats(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setTotal(totalRecords);
          const parsedData = res.data.map((item) => {
            const { confirmation, schedule, ...rest } = item;
            const parsedSchedule = schedule ? JSON.parse(schedule) : [];
            const parsedConfirmation = confirmation
              ? JSON.parse(confirmation)
              : [];
            return {
              ...rest,
              confirmation: parsedConfirmation,
              schedule: parsedSchedule.map((entry) => ({
                date: new Date(entry.date),
                from: entry.from,
                to: entry.to,
              })),
            };
          });
          setLoadData(false);
          setAllRetreatData(parsedData);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
      });
  };

  const getPerishList = async () => {
    await authApi
      .getPerishList()
      .then((response) => {
        setPerishDataList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const tempDeleteDataList = async () => {
    await retreatApi
      .tempDeleteDataList()
      .then((response) => {
        setDeleteList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const deleteRetreat = async () => {
    await retreatApi
      .tempDeleteRetreat({ id: icon_id })
      .then(async (res) => {
        if (res.success) {
          setShow(false);
          setIcon_id("");
          toast.success(res.msg);
          getAllRetreats();
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Retreat deleted temporarily ( ${icon_id} - ${data.title} )`,
            action: "Delete",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Added new log report");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
          getAllRetreats();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const restoreRetreat = async (id) => {
    await retreatApi
      .restoreRetreat({ id: id })
      .then(async (res) => {
        if (res.success) {
          toast.success(res.msg);
          tempDeleteDataList();
        } else {
          toast.error(res.msg);
          tempDeleteDataList();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const permanentDeleteRetreat = async (id) => {
    await retreatApi
      .permanentDeleteRetreat({ id: id })
      .then(async (res) => {
        if (res.success) {
          toast.success(res.msg);
          tempDeleteDataList();
        } else {
          toast.error(res.msg);
          tempDeleteDataList();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getAllRetreats(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getAllRetreats(1, 20, event.target.value);
    }
  };

  const clearfield = () => {
    setData({
      id: "",
      retreatment: "",
      title: "",
      parish: "",
      language: "",
      location: "",
      schedule: [],
      image1: "",
      image2: "",
      heading: "",
      mission_statement:
        "The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered bond between married couples in our parish and community.",
      description: "",
    });
    setNewTitle({
      parish: "",
      language: "",
      location: "",
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const handleShow1 = (row) => {
    setShowFreeze(true);
    let d = { ...alertData };
    d.value = row.freeze;
    d.retreatId = row.id;
    d.title = row.title;
    setAlertData(d);
  };

  const handleClose2 = () => {
    setShowFreeze(false);
    setAlertData([]);
  };

  const retreatFreeze = () => {
    retreatApi
      .retreatFreeze(alertData)
      .then((res) => {
        handleClose2();
        getAllRetreats();
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `${alertData.title} Retreat - ${
            alertData.value === "FREEZE" ? "UNFREEZE" : "FREEZE"
          }`,
        };
        adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Added new log report");
            }
          })
          .catch((error) => {
            console.log(error);
          });
        toast.success(res.msg);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleShowStatus = (row) => {
    setShowStatus(true);
    setAlertData(row);
  };

  const handleCloseStatus = () => {
    setShowStatus(false);
    setAlertData([]);
  };

  const retreatChangeStatus = () => {
    retreatApi
      .retreatChangeStatus({ alertData: alertData, userData: userData })
      .then((res) => {
        handleCloseStatus();
        getAllRetreats();
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `${alertData.title} Retreat - ${alertData.status}`,
        };
        adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Added new log report");
            }
          })
          .catch((error) => {
            console.log(error);
          });
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "Active/InActive",
            name: "Active/InActive",
            cell: (row) => {
              return (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    row.status === "ACTIVE" ? "ACTIVE" : "INACTIVE"
                  )}
                >
                  <Form.Check
                    className={`switchSize  ${
                      row.status === "ACTIVE"
                        ? "toggle-on-status"
                        : "toggle-off-status"
                    }`}
                    type="checkBox"
                    name="status"
                    value={row.status}
                    checked={row.status === "ACTIVE" ? true : false}
                    onChange={(e) => {
                      handleShowStatus(row);
                    }}
                    id="status"
                  />
                </OverlayTrigger>
              );
            },
            reorder: true,
            width: "90px",
          },

          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                {" "}
                {row && row.freeze === "FREEZE" ? (
                  <span className="font-size action-icons">
                    <i
                      className={`mdi mdi-pencil-box text-lg ${
                        row && row.freeze === "FREEZE"
                          ? "editRetreatDisabled"
                          : "editRetreatButton"
                      } font-size`}
                      title="Edit"
                    ></i>
                  </span>
                ) : (
                  <Link
                    to={`/create-retreat/${row.id}`}
                    className="font-size action-icons"
                  >
                    <i
                      className={`mdi mdi-pencil-box text-lg ${
                        row && row.freeze === "FREEZE"
                          ? "editRetreatDisabled"
                          : "editRetreatButton"
                      } font-size`}
                      title="Edit"
                    ></i>
                  </Link>
                )}
                <button
                  disabled={row && row.freeze === "FREEZE" ? true : false}
                  className="font-size action-icons"
                  onClick={() => handleShow(row)}
                >
                  <i
                    className={`mdi mdi-delete-forever text-md ${
                      row && row.freeze === "FREEZE"
                        ? "deleteRetreatDisabled"
                        : "deleteRetreatButton"
                    }  mx-2 font-size`}
                    title="Delete"
                  ></i>
                </button>
                <a
                  href={`/registration-retreat/${row.id}`}
                  className="font-size action-icons"
                  target="_blank"
                >
                  <i className="mdi mdi-eye" title="View"></i>
                </a>
              </>
            ),
            reorder: true,
            width: "150px",
          },
          {
            id: "Freeze",
            name: "Freeze",
            selector: (row) => {
              return (
                <Form.Check
                  className={`switchSize ${
                    row.freeze === "FREEZE" ? "toggle-on" : "toggle-off"
                  }`}
                  type="switch"
                  name="freeze"
                  value={row.freeze}
                  checked={row.freeze === "FREEZE" ? true : false}
                  onChange={(e) => {
                    handleShow1(row);
                  }}
                  id="freeze"
                />
              );
            },
            reorder: true,
            width: "100px",
          },
          {
            id: "Post Retreat Closure",
            name: "Post Retreat Closure",
            cell: (row) => (
              <div className="retreat_closure">
                {" "}
                <div>
                  {row &&
                  row.confirmation &&
                  row.confirmation[0] &&
                  row.confirmation[0].Directory === true ? (
                    <>
                      {" "}
                      <div class="green-dot"></div> <span>Directory</span>
                    </>
                  ) : (
                    <>
                      <div class="red-dot"></div> <span>Directory</span>
                    </>
                  )}
                </div>
                <div>
                  {row &&
                  row.confirmation &&
                  row.confirmation[1] &&
                  row.confirmation[1].Program === true ? (
                    <>
                      {" "}
                      <div class="green-dot"></div> <span>Program</span>
                    </>
                  ) : (
                    <>
                      <div class="red-dot"></div> <span>Program</span>
                    </>
                  )}
                </div>
                <div>
                  {row &&
                  row.confirmation &&
                  row.confirmation[2] &&
                  row.confirmation[2].roasterData === true ? (
                    <>
                      {" "}
                      <div class="green-dot"></div>{" "}
                      <span>Retreat Roster data</span>
                    </>
                  ) : (
                    <>
                      <div class="red-dot"></div>{" "}
                      <span>Retreat Roster data</span>
                    </>
                  )}
                </div>
              </div>
            ),

            sortable: true,
            reorder: true,
            width: "140px",
            style: {
              cursor: "text",
            },
          },
        ]
      : []),

    {
      id: "title",
      name: "Retreat Title",
      selector: (row) => (
        <>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              navigate(`/retreatRoster?retreatId=${row.id}`);
            }}
          >
            <div>{row.title}</div>
          </a>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              row.dateFrom === row.dateTo
                ? moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM-DD-YYYY")
                : moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM DD") +
                    " - " +
                    moment(row && row.dateTo)
                      .tz("America/New_York")
                      .format("MMM DD") +
                    ", " +
                    moment(row && row.dateFrom)
                      .tz("America/New_York")
                      .format("YYYY")
            )}
          >
            {row.dateFrom === row.dateTo ? (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD-YYYY")}
              </span>
            ) : (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD") +
                  " - " +
                  moment(row.dateTo).tz("America/New_York").format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("YYYY")}
              </span>
            )}
          </OverlayTrigger>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "language",
      name: "Language",
      selector: (row) =>
        row.language.charAt(0).toUpperCase() + row.language.slice(1),
      sortable: true,
      reorder: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "location",
      name: "Location",
      selector: (row) =>
        row.location.charAt(0).toUpperCase() + row.location.slice(1),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "fromToDate",
      name: "Retreat Dates",
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.dateFrom === row.dateTo
              ? moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD-YYYY")
              : moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM DD") +
                  " - " +
                  moment(row && row.dateTo)
                    .tz("America/New_York")
                    .format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("YYYY")
          )}
        >
          {row.dateFrom === row.dateTo ? (
            <span>
              {moment(row && row.dateFrom)
                .tz("America/New_York")
                .format("MMM-DD-YYYY")}
            </span>
          ) : (
            <span>
              {moment(row && row.dateFrom)
                .tz("America/New_York")
                .format("MMM-DD") +
                " - " +
                moment(row.dateTo).tz("America/New_York").format("MMM DD") +
                ", " +
                moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("YYYY")}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "140px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "team",
      name: "# Team Couples",
      selector: (row) => row && row.countTeam,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoster?retreatId=${row.id}&flag=2`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countTeam}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Retreatants",
      name: "# Retreatant Couples",
      selector: (row) => row && row.countRetreatant,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoster?retreatId=${row.id}&flag=1`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countRetreatant}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Directory",
      name: "Directory",
      button: true,
      cell: (row) =>
        row && row.freeze === "FREEZE" ? (
          <div
            className={`font-size ml-3 ${
              row && row.directoryFile ? "file_side" : null
            }  ${
              row && row.freeze === "FREEZE" ? "editRetreatDisabled" : null
            } action-icons`}
          >
            <a>
              <i className="mdi mdi-folder-open" title="Upload Directory"></i>
            </a>
            {row.directoryFile && (
              <a
                className="ml-2"
                title="View Directory"
                href={row.directoryFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                <i
                  class="mdi mdi-download"
                  title="View Directory"
                  style={{ color: "#4cd964", marginLeft: "0.5rem" }}
                ></i>
              </a>
            )}
          </div>
        ) : (
          <div
            className={`font-size ml-3 action-icons ${
              row && row.directoryFile ? "file_side" : null
            }`}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                handleUploadDirectory(row); // Trigger upload modal
              }}
              style={{ cursor: "pointer" }}
              title="Upload Directory"
              className="font-size"
            >
              <i className="mdi mdi-folder-open"></i>
            </a>
            {row.directoryFile && (
              <a
                className="ml-2"
                title="View Directory"
                href={row.directoryFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                <i
                  class="mdi mdi-download"
                  title="View Directory"
                  style={{ color: "#4cd964", marginLeft: "0.5rem" }}
                ></i>
              </a>
            )}
          </div>
        ),
      reorder: true,
      width: "110px",
    },
    {
      id: "Program",
      name: "Program",
      button: true,
      cell: (row) =>
        row && row.freeze === "FREEZE" ? (
          <div
            className={`font-size ml-3 ${
              row && row.programFile ? "file_side" : null
            } ${
              row && row.freeze === "FREEZE" ? "editRetreatDisabled" : null
            } action-icons`}
            style={{ cursor: "pointer" }}
          >
            <a>
              <i
                className="mdi mdi-calendar-multiselect"
                title="Upload Program"
              ></i>
            </a>
            {row.programFile && (
              <a
                className="ml-2"
                title="View Program"
                href={row.programFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                <i
                  class="mdi mdi-download"
                  title="View Program"
                  style={{ color: "#4cd964", marginLeft: "0.5rem" }}
                ></i>
              </a>
            )}
          </div>
        ) : (
          <div
            className={`font-size ml-3 action-icons ${
              row && row.programFile ? "file_side" : null
            }`}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                handleUploadProgram(row); // Trigger upload modal
              }}
              style={{ cursor: "pointer" }}
              title="Upload program"
              className="font-size"
            >
              <i className="mdi mdi-calendar-multiselect"></i>
            </a>
            {row.programFile && (
              <a
                className="ml-2"
                title="View Program"
                href={row.programFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                <i
                  class="mdi mdi-download"
                  title="View Program"
                  style={{ color: "#4cd964", marginLeft: "0.5rem" }}
                ></i>
              </a>
            )}
          </div>
        ),
      reorder: true,
      width: "110px",
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(
              row && row.createdAt,
              "MM-DD-YYYY hh:mm:a"
            )}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "170px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "created_by",
      name: "Created By",
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.contact_name &&
              row.contact_name !== "" &&
              row.contact_name !== null
              ? row.contact_name
              : "ADMIN"
          )}
        >
          {row.contact_name &&
          row.contact_name !== "" &&
          row.contact_name !== null ? (
            <span
              onClick={() => {
                setContactData(true);
                setRetreatDetail(row);
              }}
              className="sampleData"
            >
              {row.contact_name}
            </span>
          ) : (
            <span>ADMIN</span>
          )}
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "confirmed_by",
      name: "Confirmed By",
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Confirmed data List")}
        >
          <span
            onClick={() => {
              setConfirmedData(true);
              setRetreatDetail(row);
            }}
          >
            <i
              className="mdi mdi-view-headline font-size action-icons"
              title="Confirmed List"
            ></i>
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "pointer",
      },
    },
  ];

  const handleShow = (item) => {
    setRetreatDetail(item);
    setShow(true);
    setIcon_id(item.id);
    let d = { ...data };
    d.id = item.id;
    d.title = item.title;
    setData(d);
  };

  const handleClose = () => {
    getAllRetreats();
    setShow(false);
  };

  const renderModel = () => {
    return (
      <Modal
        show={showRegModal}
        onHide={() => {
          setShowREgModal(false);
          setDirectory([]);
          setProgram([]);
          getAllRetreats();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Retreat BackUp</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ListGroup>
            {deleteList &&
              deleteList.map((item, i) => (
                <ListGroup.Item key={i} className="d-flex restore-delete">
                  <div>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/retreatRoster?retreatId=${item.id}`);
                      }}
                    >
                      <div>{item.title}</div>
                    </a>
                    {item.dateFrom === item.dateTo ? (
                      <span>
                        {moment(item && item.dateFrom)
                          .tz("America/New_York")
                          .format("MMM-DD-YYYY")}
                      </span>
                    ) : (
                      <span>
                        {moment(item && item.dateFrom)
                          .tz("America/New_York")
                          .format("MMM-DD") +
                          " - " +
                          moment(item.dateTo)
                            .tz("America/New_York")
                            .format("MMM DD") +
                          ", " +
                          moment(item && item.dateFrom)
                            .tz("America/New_York")
                            .format("YYYY")}
                      </span>
                    )}
                  </div>
                  <div>
                    {" "}
                    <button
                      className="font-size button-33"
                      onClick={() => restoreRetreat(item.id)}
                    >
                      Restore
                    </button>
                    <button
                      className="font-size button-35"
                      onClick={() => permanentDeleteRetreat(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowREgModal(false);
              getAllRetreats();
              setDirectory([]);
              setProgram([]);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCloseAlert = () => {
    setContactData(false);
    setConfirmedData(false);
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 dirPage mb-2">
        <a href={`/retreat-backup`} className="mb-4">
          Back-up of Retreats
        </a>
      </div>
      <div className="col-lg-12 d-flex flex-column mt-1">
        <div className="card  custom-card">
          <div className="card-body">
            <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
              <h4 className="card-title">Retreats</h4>
              {userData && userData.userRole === "ADMIN" ? (
                <div className="d-flex">
                  <div className="search">
                    <div className="input-group setSearch">
                      <input
                        onChange={SearchData}
                        type="text"
                        className="form-control searchData"
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                        >
                          <i className="icon-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <Link to="/create-retreat" className="addNewRet">
                    <button
                      onClick={() => {
                        // setShowREgModal(true);
                        clearfield();
                        // setActiveTab("Retreat");
                      }}
                      className="btn btn-primary btn-sm text-white mb-0 me-0 alignbtn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="mdi mdi-account-multiple"></i> Add New
                      Retreat
                    </button>
                  </Link>
                </div>
              ) : null}
            </div>
            <CustomDataTable
              data={allRetreatData}
              countPerPage={20}
              isServerSide={true}
              pagination={true}
              sortServer={true}
              total={total}
              columns={columns}
              dataFetchApi={getAllRetreats}
              pointerOnHover={true}
              highlightOnHover={true}
              // fixedHeader={true}
            />
            {/* } */}
          </div>
        </div>
      </div>
      {showRegModal ? renderModel() : null}
      {show ? (
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={deleteRetreat}
          title={retreatDetail.title}
          selectionDelete="temporary"
        ></DeleteModel>
      ) : null}
      {contactData ? (
        <AlertDelete
          show={contactData}
          alertHeader="Contact Information"
          alerMessage={
            <div>
              <div>
                <h5 className="mt-4">
                  <strong>Contact Name :</strong> {retreatDetail.contact_name}
                </h5>
                <h5 className="mt-4">
                  <strong>Contact Email :</strong> {retreatDetail.contact_email}
                </h5>
                <h5 className="mt-4">
                  <strong>Contact Mobile :</strong>{" "}
                  {retreatDetail.contact_mobile}
                </h5>
              </div>
              ​
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}

      {confirmedData ? (
        <AlertDelete
          show={confirmedData}
          alertHeader="Confirmed By"
          alerMessage={
            retreatDetail.confirmation &&
            retreatDetail.confirmation.length > 0 ? (
              <div>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/retreatRoster?retreatId=${retreatDetail.id}`);
                  }}
                >
                  <div>{retreatDetail.title}</div>
                </a>
                {retreatDetail.dateFrom === retreatDetail.dateTo ? (
                  <span>
                    {moment(retreatDetail && retreatDetail.dateFrom)
                      .tz("America/New_York")
                      .format("MMM-DD-YYYY")}
                  </span>
                ) : (
                  <span>
                    {moment(retreatDetail && retreatDetail.dateFrom)
                      .tz("America/New_York")
                      .format("MMM-DD") +
                      " - " +
                      moment(retreatDetail.dateTo)
                        .tz("America/New_York")
                        .format("MMM DD") +
                      ", " +
                      moment(retreatDetail && retreatDetail.dateFrom)
                        .tz("America/New_York")
                        .format("YYYY")}
                  </span>
                )}

                <h4 className="mt-3">Directory</h4>
                <div>
                  <p className="mt-1">
                    <strong>Confirm Status: </strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[0] &&
                    retreatDetail.confirmation[0].Directory
                      ? "Confirmed"
                      : "Not Confirmed"}
                  </p>
                  <p>
                    <strong>Confirmed By: </strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[0] &&
                    retreatDetail.confirmation[0].confirmedByName
                      ? `${retreatDetail.confirmation[0].confirmedByName} (${
                          retreatDetail.confirmation[0].confirmedByEmail ||
                          "Nil"
                        })`
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Role: </strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[0] &&
                    retreatDetail.confirmation[0].confirmedByRole
                      ? retreatDetail.confirmation[0].confirmedByRole
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Confirmed Date:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[0] &&
                    retreatDetail.confirmation[0].ConfirmedDate
                      ? Helpers.convertUtcToTimezone(
                          retreatDetail.confirmation[0].ConfirmedDate,
                          "MM-DD-YYYY hh:mm:a"
                        )
                      : "Nil"}
                  </p>
                </div>
                <hr className="mt-3" style={{ border: "1px solid #ccc" }} />

                <h4>Program</h4>
                <div>
                  <p className="mt-1">
                    <strong>Confirm Status:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[1] &&
                    retreatDetail.confirmation[1].Program
                      ? "Confirmed"
                      : "Not Confirmed"}
                  </p>
                  <p>
                    <strong>Confirmed By:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[1] &&
                    retreatDetail.confirmation[1].confirmedByName
                      ? `${retreatDetail.confirmation[1].confirmedByName} (${
                          retreatDetail.confirmation[1].confirmedByEmail ||
                          "Nil"
                        })`
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Role:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[1] &&
                    retreatDetail.confirmation[1].confirmedByRole
                      ? retreatDetail.confirmation[1].confirmedByRole
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Confirmed Date:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[1] &&
                    retreatDetail.confirmation[1].ConfirmedDate
                      ? Helpers.convertUtcToTimezone(
                          retreatDetail.confirmation[1].ConfirmedDate,
                          "MM-DD-YYYY hh:mm:a"
                        )
                      : "Nil"}
                  </p>
                </div>
                <hr className="mt-3" style={{ border: "1px solid #ccc" }} />

                <h4>Roster Data</h4>
                <div>
                  <p className="mt-1">
                    <strong>Confirm Status:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[2] &&
                    retreatDetail.confirmation[2].roasterData
                      ? "Confirmed"
                      : "Not Confirmed"}
                  </p>
                  <p>
                    <strong>Confirmed By:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[2] &&
                    retreatDetail.confirmation[2].confirmedByName
                      ? `${retreatDetail.confirmation[2].confirmedByName} (${
                          retreatDetail.confirmation[2].confirmedByEmail ||
                          "Nil"
                        })`
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Role:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[2] &&
                    retreatDetail.confirmation[2].confirmedByRole
                      ? retreatDetail.confirmation[2].confirmedByRole
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Confirmed Date:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[2] &&
                    retreatDetail.confirmation[2].ConfirmedDate
                      ? Helpers.convertUtcToTimezone(
                          retreatDetail.confirmation[2].ConfirmedDate,
                          "MM-DD-YYYY hh:mm:a"
                        )
                      : "Nil"}
                  </p>
                </div>
                <hr className="mt-3" style={{ border: "1px solid #ccc" }} />

                <h4>Retreat Status</h4>
                <div>
                  <p className="mt-1">
                    <strong>Status:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[3] &&
                    retreatDetail.confirmation[3].retreatStatus === "ACTIVE"
                      ? "ACTIVE"
                      : "INACTIVE"}
                  </p>
                  <p>
                    <strong>By:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[3] &&
                    retreatDetail.confirmation[3].confirmedByName
                      ? `${retreatDetail.confirmation[3].confirmedByName} (${
                          retreatDetail.confirmation[3].confirmedByEmail ||
                          "Nil"
                        })`
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Role:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[3] &&
                    retreatDetail.confirmation[3].confirmedByRole
                      ? retreatDetail.confirmation[3].confirmedByRole
                      : "Nil"}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {retreatDetail &&
                    retreatDetail.confirmation &&
                    retreatDetail.confirmation[3] &&
                    retreatDetail.confirmation[3].ConfirmedDate
                      ? Helpers.convertUtcToTimezone(
                          retreatDetail.confirmation[3].ConfirmedDate,
                          "MM-DD-YYYY hh:mm:a"
                        )
                      : "Nil"}
                  </p>
                </div>
              </div>
            ) : (
              <div>No confirmation data available</div>
            )
          }
          handleClose={handleCloseAlert}
        />
      ) : null}

      {/* File Upload Modal */}
      <FileUploadModal
        show={showUploadModal}
        handleClose={handleUploadModalClose}
        rowId={selectedRowId}
        handleUploadSuccess={handleUploadSuccess}
        fileType={fileType}
        onDeleteFile={handleDeleteFile}
        existingFileUrl={existingFileUrl}
        modalTitle={uploadModalTitle}
      />
      {showFreeze ? (
        <AlertModal
          show={showFreeze}
          handleClose={handleClose2}
          message={`Are you sure you want to ${
            alertData.value === "FREEZE" ? "UNFREEZE" : "FREEZE"
          } this retreat?`}
          actionFunction={retreatFreeze}
        />
      ) : null}
      {showStatus ? (
        <AlertModal
          show={showStatus}
          handleClose={handleCloseStatus}
          message={`Are you sure you want to ${
            alertData.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
          } this retreat?`}
          actionFunction={retreatChangeStatus}
        />
      ) : null}
    </div>
  );
};
export default ManageRetreat;
