import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import Profile from "../../assets/images/couple-default.png";
import DatePicker from "react-datepicker";
import CustomDataTable from "../../components/CustomDataTable";
import AdminApi from "../../api/admin";
import AuthApi from "../../api/authApi";
import moment from "moment";
import { toast } from "react-toastify";

const UpdateCouplePopUp = ({
  showRegModal,
  onHide,
  closeButton,
  data,
  isEditAdmin,
  parishList,
  ediUserIdClicked,
  CloseCompose,
  roasterCount,
  userData,
  roastersOfCoupleList,
  coupleOldValues,
  setShowREgModal,
  setEdiUserIdClicked,
  getAllCouples,
  setData,
  oldValues,
  setOldValues,
  isTempUpload,
  isTempUpload2,
  setIsTempUpload,
  // setIsTempUpload2,
}) => {
  const [validated, setValidated] = useState(false);
  const [oldData, setOldData] = useState([]);
  let adminApi = new AdminApi();

  // useEffect(() => {
  //   if (coupleData && coupleOldValues) {
  //     setData(coupleData);
  //     setOldValues(coupleOldValues);
  //   } else {
  //     authApi
  //       .checkPKCouple()
  //       .then((res) => {
  //         if (res.success) {
  //           if (res.data != null) {
  //             let incPK = res.data + 1;
  //             setAlreadyPk(incPK);
  //             let d = { ...data };
  //             d.primaryKey = incPK;
  //             setData(d);
  //           } else {
  //             let defaultPK = 1001;
  //             setAlreadyPk(defaultPK);
  //             let d = { ...data };
  //             d.primaryKey = defaultPK;
  //             setData(d);
  //           }
  //         } else {
  //           toast.error(res.msg);
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error(error);
  //       });
  //   }
  //   // if (!coupleData.id) {
  //   //   checkPKCouple();
  //   // }
  // }, [coupleData]);

  const columns1 = [
    {
      id: "title",
      name: "Retreats",
      selector: (row) => (
        <div className="titleWithDate">
          {userData && userData.userRole === "SUBADMIN" ? (
            <div>
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              }`}
            </div>
          ) : (
            <a
              href={`/retreatRoster?retreatId=${row.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="retreat_title"
            >
              <div>
                {`${
                  row.title !== null && row.title != ""
                    ? `${
                        row.title.charAt(0).toUpperCase() + row.title.slice(1)
                      }`
                    : ""
                }`}
              </div>
            </a>
          )}
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              moment(row.dateFrom).format("MMM DD") +
                " - " +
                moment(row.dateTo).format("MMM DD") +
                ", " +
                moment(row.dateFrom).format("YYYY")
            )}
          >
            <span>
              {moment(row.dateFrom).format("MMM DD") +
                " - " +
                moment(row.dateTo).format("MMM DD") +
                ", " +
                moment(row.dateFrom).format("YYYY")}
            </span>
          </OverlayTrigger>
        </div>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          {row && row.attendeeType == "Team" ? (
            <span className="badge badge-danger">{row.attendeeType}</span>
          ) : (
            <span className="badge badge-success">{row.attendeeType}</span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Role",
      selector: (row) => row && row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service Title",
      name: "Service Title",
      selector: (row) => row && row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span>{row.service ? row.service : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "230px",
      style: {
        cursor: "text",
      },
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  // const uploadMarriagePhoto = () => {
  //   document.getElementById("MarriagePhotoUpload").click();
  //   setIsTempUpload2(true);
  // };

  const remove = () => {
    let d = { ...data };
    d.imageUrl = "";
    setData(d);
    setIsTempUpload(false);
  };

  // const removeMarriagePhoto = () => {
  //   let d = { ...data };
  //   d.marriageImageUrl = "";
  //   setData(d);
  //   setIsTempUpload2(false);
  // };

  const handleDatePicker = (e) => {
    let o = { ...data };
    o.anniversary_date = e;
    setData(o);
  };

  const handleAgeChanges = (e) => {
    const { name, checked } = e.target;
    let o = { ...data };
    o.under_age_35 = checked ? "YES" : "NO";
    setData(o);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let hearUsArray = Array.isArray(data.hear_us)
      ? data.hear_us
      : JSON.parse(data.hear_us);
    let updatedOptions;
    if (checked) {
      updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
    } else {
      updatedOptions = hearUsArray.filter((option) => option !== name);
    }
    setData({ ...data, hear_us: updatedOptions });
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    let o = { ...oldData };
    if (name === "hisFirstName") {
      o.hisFirstName = oldValues.hisFirstName;
    } else if (name === "hisLastName") {
      o.hisLastName = oldValues.hisLastName;
    } else if (name === "hisEmail") {
      o.hisEmail = oldValues.hisEmail;
    } else if (name === "hisMobile") {
      o.hisMobile = oldValues.hisMobile;
    } else if (name === "herFirstName") {
      o.herFirstName = oldValues.herFirstName;
    } else if (name === "herLastName") {
      o.herLastName = oldValues.herLastName;
    } else if (name === "herEmail") {
      o.herEmail = oldValues.herEmail;
    } else if (name === "herMobile") {
      o.herMobile = oldValues.herMobile;
    } else if (name === "parishId") {
      o.parishId = oldValues.parishId;
    } else if (name === "notes") {
      o.notes = oldValues.notes;
    } else if (name === "zip") {
      o.zip = oldValues.zip;
    } else if (name === "city") {
      o.city = oldValues.city;
    } else if (name === "address") {
      o.address = oldValues.address;
    } else if (name === "allergies") {
      o.allergies = oldValues.allergies;
    } else if (name === "anniversary_date") {
      o.anniversary_date = oldValues.anniversary_date;
    } else if (name === "state") {
      o.state = oldValues.state;
    } else if (name === "imageUrl") {
      o.imageUrl = oldValues.imageUrl;
    } 
    // else if (name === "marriageImageUrl") {
    //   o.marriageImageUrl = oldValues.marriageImageUrl;
    // }
     else if (name === "under_age_35") {
      o.under_age_35 = oldValues.under_age_35;
    } else if (name === "emergency_name1") {
      o.emergency_name1 = oldValues.emergency_name1;
    } else if (name === "emergency_relationship1") {
      o.emergency_relationship1 = oldValues.emergency_relationship1;
    } else if (name === "emergency_phone1") {
      o.emergency_phone1 = oldValues.emergency_phone1;
    } else if (name === "emergency_name2") {
      o.emergency_name1 = oldValues.emergency_name1;
    } else if (name === "emergency_relationship2") {
      o.emergency_relationship1 = oldValues.emergency_relationship1;
    } else if (name === "emergency_phone2") {
      o.emergency_phone1 = oldValues.emergency_phone1;
    } else if (name === "hear_us") {
      o.hear_us = oldValues.hear_us;
    } else if (name === "referral_from_a_friend_or_family_member") {
      o.referral_from_a_friend_or_family_member =
        oldValues.referral_from_a_friend_or_family_member;
    }
    setOldData(o);
    // Check if the input is for an image
    // if (name === "imageUrl" || name === "marriageImageUrl") {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
    //     if (fileSizeInMB > 10) {
    //       alert("File size should be less than 10 MB");
    //       return;
    //     } else {
    //       d[name] = file;
    //       const successMessage =
    //         name === "imageUrl"
    //           ? "The profile image has been successfully added."
    //           : "The Wedding image has been successfully added.";
    //       toast.success(successMessage);
    //     }
    //   }
    // } else {
    //   d[name] = value;
    // }

    // ONLY FOR UPLOAD COUPLE IMAGE
    if (name === "imageUrl") {
      const file = e.target.files[0];
      if (file) {
        const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
        if (fileSizeInMB > 10) {
          alert("File size should be less than 10 MB");
          return;
        } else {
          d[name] = file;
          const successMessage =
            name === "imageUrl"
              ? "The profile image has been successfully added."
              : "";
          toast.success(successMessage);
        }
      }
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      if (
        ediUserIdClicked &&
        ediUserIdClicked !== "" &&
        ediUserIdClicked !== null
      ) {
        let data1 = new FormData();
        if (data.imageUrl !== "") {
          data1.append("file", data.imageUrl);
        }
        // if (data.marriageImageUrl !== "") {
        //   data1.append("marriage_file", data.marriageImageUrl);
        // }
        let stateRef = { ...data };
        data1.append("fromData", JSON.stringify(stateRef));
        adminApi
          .editCouple(data1)
          .then(async (res) => {
            if (res.success) {
              toast.success(res.msg);
              if (
                oldData.hisFirstName ||
                (oldData.hisFirstName === "" &&
                  oldData.hisFirstName !== res.data.hisFirstName)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HisFirstName of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.hisFirstName ? oldData.hisFirstName : "Nil"
                    }" to "${
                      res.data.hisFirstName ? res.data.hisFirstName : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.hisLastName ||
                (oldData.hisLastName === "" &&
                  oldData.hisLastName !== res.data.hisLastName)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HisLastName of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.hisLastName ? oldData.hisLastName : "Nil"
                    }" to "${
                      res.data.hisLastName ? res.data.hisLastName : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.hisEmail ||
                (oldData.hisEmail === "" &&
                  oldData.hisEmail !== res.data.hisEmail)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HisEmail of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.hisEmail ? oldData.hisEmail : "Nil"
                    }" to "${res.data.hisEmail ? res.data.hisEmail : "Nil"}"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.hisMobile ||
                (oldData.hisMobile === "" &&
                  oldData.hisMobile !== res.data.hisMobile)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HisMobile of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.hisMobile ? oldData.hisMobile : "Nil"
                    }" to "${res.data.hisMobile ? res.data.hisMobile : "Nil"}"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.herFirstName ||
                (oldData.herFirstName === "" &&
                  oldData.herFirstName !== res.data.herFirstName)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HerFirstName of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.herFirstName ? oldData.herFirstName : "Nil"
                    }" to "${
                      res.data.herFirstName ? res.data.herFirstName : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.herLastName ||
                (oldData.herLastName === "" &&
                  oldData.herLastName !== res.data.herLastName)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HerLastName of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.herLastName ? oldData.herLastName : "Nil"
                    }" to "${
                      res.data.herLastName ? res.data.herLastName : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.herEmail ||
                (oldData.herEmail === "" &&
                  oldData.herEmail !== res.data.herEmail)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HerEmail of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.herEmail ? oldData.herEmail : "Nil"
                    }" to "${res.data.herEmail ? res.data.herEmail : "Nil"}"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.herMobile ||
                (oldData.herMobile === "" &&
                  oldData.herMobile !== res.data.herMobile)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated HerMobile of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.herMobile ? oldData.herMobile : "Nil"
                    }" to "${res.data.herMobile ? res.data.herMobile : "Nil"}"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              let oldparish = parishList.find((i) => i.id === oldData.parishId);
              let newparish = parishList.find(
                (i) => i.id === res.data.parishId
              );
              if (oldData.parishId || oldData.parishId === null) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Parish of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldparish &&
                      oldparish.parish &&
                      oldparish.parish !== undefined
                        ? oldparish.parish
                        : "Nil"
                    }" to "${
                      newparish &&
                      newparish.parish &&
                      newparish.parish !== undefined
                        ? newparish.parish
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.notes ||
                (oldData.notes === "" && oldData.notes !== res.data.notes)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Notes of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.notes ? oldData.notes : "Nil"}" to "${
                      res.data.notes ? res.data.notes : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.zip ||
                (oldData.zip === "" && oldData.zip !== res.data.zip)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Zip of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.zip ? oldData.zip : "Nil"}" to "${
                      res.data.zip ? res.data.zip : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.city ||
                (oldData.city === "" && oldData.city !== res.data.city)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated City of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.city ? oldData.city : "Nil"}" to "${
                      res.data.city ? res.data.city : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.state ||
                (oldData.state === "" && oldData.state !== res.data.state)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated State of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.state ? oldData.state : "Nil"}" to "${
                      res.data.state ? res.data.state : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.imageUrl ||
                (oldData.imageUrl === "" &&
                  oldData.imageUrl !== res.data.imageUrl)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated ImageUrl of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              // if (
              //   oldData.marriageImageUrl ||
              //   (oldData.marriageImageUrl === "" &&
              //     oldData.marriageImageUrl !== res.data.marriageImageUrl)
              // ) {
              //   let report = {
              //     byWhom:
              //       `${
              //         userData && userData.userName ? userData.userName : null
              //       } ` +
              //       ` (${
              //         userData && userData.userRole ? userData.userRole : null
              //       })`,
              //     pagelink: window.location.href,
              //     description:
              //       `Updated marriageImageUrl of Couple ` +
              //       `(${
              //         res && res.data && res.data.primaryKey
              //           ? `${res.data.primaryKey} - `
              //           : ""
              //       }` +
              //       `${
              //         res && res.data && res.data.hisLastName
              //           ? `${res.data.hisLastName}`
              //           : ""
              //       }` +
              //       `${
              //         res && res.data && res.data.hisFirstName
              //           ? `, ${res.data.hisFirstName}`
              //           : ""
              //       }` +
              //       `${
              //         res && res.data && res.data.herFirstName
              //           ? ` and  ${res.data.herFirstName}`
              //           : ""
              //       })`,
              //     action: "Update",
              //   };
              //   await adminApi
              //     .addLogReport(report)
              //     .then((response) => {
              //       if (response.success) {
              //         console.log("Created new log report.");
              //       }
              //     })
              //     .catch((error) => {
              //       console.log(error);
              //     });
              // }

              if (
                oldData.address ||
                (oldData.address === "" && oldData.address !== res.data.address)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Address of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.address ? oldData.address : "Nil"}" to "${
                      res.data.address ? res.data.address : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.allergies ||
                (oldData.allergies === "" &&
                  oldData.allergies !== res.data.allergies)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "dietary restrictions or physical limitations" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.allergies ? oldData.allergies : "Nil"
                    }" to "${res.data.allergies ? res.data.allergies : "Nil"}"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.anniversary_date ||
                (oldData.anniversary_date === "" &&
                  oldData.anniversary_date !== res.data.anniversary_date)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Anniversary Date of Couple ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.anniversary_date
                        ? oldData.anniversary_date
                        : "Nil"
                    }" to "${
                      res.data.anniversary_date
                        ? res.data.anniversary_date
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.under_age_35 ||
                (oldData.under_age_35 === "" &&
                  oldData.under_age_35 !== res.data.under_age_35)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Under Age 35" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.under_age_35 ? oldData.under_age_35 : "Nil"
                    }" to "${
                      res.data.under_age_35 ? res.data.under_age_35 : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.emergency_name1 ||
                (oldData.emergency_name1 === "" &&
                  oldData.emergency_name1 !== res.data.emergency_name1)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Name1" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_name1 ? oldData.emergency_name1 : "Nil"
                    }" to "${
                      res.data.emergency_name1
                        ? res.data.emergency_name1
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.emergency_relationship1 ||
                (oldData.emergency_relationship1 === "" &&
                  oldData.emergency_relationship1 !==
                    res.data.emergency_relationship1)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Relationship1" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_relationship1
                        ? oldData.emergency_relationship1
                        : "Nil"
                    }" to "${
                      res.data.emergency_relationship1
                        ? res.data.emergency_relationship1
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.emergency_phone1 ||
                (oldData.emergency_phone1 === "" &&
                  oldData.emergency_phone1 !== res.data.emergency_phone1)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Phone1" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_phone1
                        ? oldData.emergency_phone1
                        : "Nil"
                    }" to "${
                      res.data.emergency_phone1
                        ? res.data.emergency_phone1
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.emergency_name2 ||
                (oldData.emergency_name2 === "" &&
                  oldData.emergency_name2 !== res.data.emergency_name2)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Name2" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_name2 ? oldData.emergency_name2 : "Nil"
                    }" to "${
                      res.data.emergency_name2
                        ? res.data.emergency_name2
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.emergency_relationship2 ||
                (oldData.emergency_relationship2 === "" &&
                  oldData.emergency_relationship2 !==
                    res.data.emergency_relationship2)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Relationship2" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_relationship2
                        ? oldData.emergency_relationship2
                        : "Nil"
                    }" to "${
                      res.data.emergency_relationship2
                        ? res.data.emergency_relationship2
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.emergency_phone2 ||
                (oldData.emergency_phone2 === "" &&
                  oldData.emergency_phone2 !== res.data.emergency_phone2)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Emergency Phone2" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.emergency_phone2
                        ? oldData.emergency_phone2
                        : "Nil"
                    }" to "${
                      res.data.emergency_phone2
                        ? res.data.emergency_phone2
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.hear_us ||
                (oldData.hear_us === "" && oldData.hear_us !== res.data.hear_us)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Hear about us" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${oldData.hear_us ? oldData.hear_us : "Nil"}" to "${
                      res.data.hear_us ? res.data.hear_us : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.referral_from_a_friend_or_family_member ||
                (oldData.referral_from_a_friend_or_family_member === "" &&
                  oldData.referral_from_a_friend_or_family_member !==
                    res.data.referral_from_a_friend_or_family_member)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Updated Couple's column on "Referral from a friend/Family member" ` +
                    `(${
                      res && res.data && res.data.primaryKey
                        ? `${res.data.primaryKey} - `
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisLastName
                        ? `${res.data.hisLastName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.hisFirstName
                        ? `, ${res.data.hisFirstName}`
                        : ""
                    }` +
                    `${
                      res && res.data && res.data.herFirstName
                        ? ` and  ${res.data.herFirstName}`
                        : ""
                    })` +
                    ` From "${
                      oldData.referral_from_a_friend_or_family_member
                        ? oldData.referral_from_a_friend_or_family_member
                        : "Nil"
                    }" to "${
                      res.data.referral_from_a_friend_or_family_member
                        ? res.data.referral_from_a_friend_or_family_member
                        : "Nil"
                    }"`,
                  action: "Update",
                };
                await adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              clearfield();
              setData({
                id: "",
                hisFirstName: "",
                hisLastName: "",
                hisEmail: "",
                hisMobile: "",
                herFirstName: "",
                herLastName: "",
                herEmail: "",
                herMobile: "",
                city: "",
                state: "",
                zip: "",
                notes: "",
                imageUrl: "",
                // marriageImageUrl: "",
                parishId: null,
                address: "",
                allergies: "",
                anniversary_date: "",
                under_age_35: false,
                emergency_name1: "",
                emergency_relationship1: "",
                emergency_phone1: "",
                emergency_name2: "",
                emergency_relationship2: "",
                emergency_phone2: "",
                hear_us: [],
                referral_from_a_friend_or_family_member: "",
              });
              setOldData([]);
              setShowREgModal(false);
              setEdiUserIdClicked("");
              getAllCouples();
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        let data1 = new FormData();
        data1.append("file", data.imageUrl);
        // data1.append("marriage_file", data.marriageImageUrl);
        let stateRef = { ...data };
        delete stateRef.imageUrl;
        // delete stateRef.marriageImageUrl;
        data1.append("fromData", JSON.stringify(stateRef));
        adminApi
          .createCouple(data1)
          .then(async (res) => {
            if (res.success) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Added new couple ` +
                  `(${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? ` - ${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })`,
                action: "Add",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              toast.success(res.msg);
              clearfield();
              setEdiUserIdClicked("");
              setShowREgModal(false);
              getAllCouples();
              remove();
              setData({
                id: "",
                hisFirstName: "",
                hisLastName: "",
                hisEmail: "",
                hisMobile: "",
                herFirstName: "",
                herLastName: "",
                herEmail: "",
                herMobile: "",
                city: "",
                state: "",
                zip: "",
                notes: "",
                imageUrl: "",
                // marriageImageUrl: "",
                address: "",
                allergies: "",
                anniversary_date: "",
                under_age_35: false,
                emergency_name1: "",
                emergency_relationship1: "",
                emergency_phone1: "",
                emergency_name2: "",
                emergency_relationship2: "",
                emergency_phone2: "",
                hear_us: [],
                referral_from_a_friend_or_family_member: "",
              });
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setValidated(true);
  };

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
    });
    setValidated(false);
  };

  return (
    <div className="tab_headers">
      <Modal show={showRegModal} onHide={onHide} size="lg">
        <Modal.Header closeButton={closeButton}></Modal.Header>
        <Tabs defaultActiveKey="Couple Details" id="uncontrolled-tab-example">
          <Tab
            eventKey="Couple Details"
            title="Couple Details"
            className="tab-content"
          >
            <Form
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
              className="forms-sample"
            >
              <Modal.Body>
                <p className="card-description">Enter couple details here..</p>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Couple Id</Form.Label>
                      <Form.Control
                        required
                        size="sm"
                        type="text"
                        name="primaryKey"
                        id="primaryKey"
                        placeholder="Couple Id"
                        value={data.primaryKey}
                        className="form-control"
                        onChange={(e) => handleChanges(e)}
                        readOnly
                      />
                      <Form.Control.Feedback type="invalid">
                        Couple Id is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="mt-4">
                      {" "}
                      <label className="bold mt-2" htmlFor="exampleInputEmail1">
                        If you or your spouse are 35 years of age or younger,
                        click this box:
                      </label>
                      <label className="form-check-label mt-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="under_age_35"
                          checked={data.under_age_35 === "YES" ? true : false}
                          onChange={handleAgeChanges}
                        />
                        Yes
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      {data.imageUrl == "" || data.imageUrl == undefined ? (
                        <div className="edit-profile-img mb-2 text-center">
                          <img src={Profile} />
                        </div>
                      ) : (
                        <div className="edit-profile-img mb-2 text-center">
                          {isTempUpload ? (
                            <>
                              <img src={URL.createObjectURL(data?.imageUrl)} />
                            </>
                          ) : (
                            <>
                              <img src={data?.imageUrl} />
                            </>
                          )}
                        </div>
                      )}{" "}
                      <div className="text-center mt-3">
                        {data.imageUrl == "" || data.imageUrl == undefined ? (
                          <>
                            <input
                              name="imageUrl"
                              className="pt-1"
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              id="CoupleProfileUpload"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Button
                              variant="primary"
                              className="mb-1 text-center"
                              onClick={(e) => {
                                upload();
                              }}
                            >
                              Upload Profile Image
                            </Button>
                          </>
                        ) : userData &&
                          userData.userRole === "SUBADMIN" ? null : (
                          <Button
                            variant="danger"
                            className="mb-1 mt-2 "
                            onClick={(e) => {
                              remove();
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  {/* <Col lg={3} md={3} xs={12}>
                    <Form.Group>
                      {data.marriageImageUrl == "" ||
                      data.marriageImageUrl == undefined ? (
                        <div className="edit-profile-img mb-2 text-center">
                          <img src={Profile} />
                        </div>
                      ) : (
                        <div className="edit-profile-img mb-2 text-center">
                          {isTempUpload2 ? (
                            <>
                              <img
                                src={URL.createObjectURL(
                                  data?.marriageImageUrl
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <img src={data?.marriageImageUrl} />
                            </>
                          )}
                        </div>
                      )}
                      <div className="text-center mt-3">
                        {data.marriageImageUrl == "" ||
                        data.marriageImageUrl == undefined ? (
                          <>
                            <input
                              name="marriageImageUrl"
                              className="pt-1"
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              id="MarriagePhotoUpload"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Button
                              variant="primary"
                              className="mb-1 text-center"
                              onClick={(e) => {
                                uploadMarriagePhoto();
                              }}
                            >
                              Upload Marriage Image
                            </Button>
                          </>
                        ) : userData &&
                          userData.userRole === "SUBADMIN" ? null : (
                          <Button
                            variant="danger"
                            className="mb-1 mt-2 "
                            onClick={(e) => {
                              removeMarriagePhoto();
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                  </Col> */}
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <label className="badge badge-outline-primary rounded hisLabel">
                      His Info
                    </label>
                    <div id="his-info">
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="First Name"
                            value={data.hisFirstName}
                            className="form-control"
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="First Name"
                            value={data.hisLastName}
                            className="form-control"
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group col">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={data.hisEmail}
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group col">
                          <Form.Label>His Mobile</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisMobile"
                            id="hisMobile"
                            placeholder="Mobile"
                            value={data.hisMobile}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Mobile is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <label className="badge badge-outline-primary rounded herLabel">
                      Her Info
                    </label>
                    <div id="her-info">
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="First Name"
                            value={data.herFirstName}
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Last Name"
                            value={data.herLastName}
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={data.herEmail}
                            onChange={(e) => handleChanges(e)}
                            readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Mobile</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herMobile"
                            id="herMobile"
                            placeholder="Mobile"
                            value={data.herMobile}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Mobile is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <div className="row">
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={data.address}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Address is required!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>State</Form.Label>
                    <Form.Select
                      className="form-control minimal"
                      size="sm"
                      type="text"
                      name="state"
                      id="state"
                      placeholder="State"
                      value={data.state}
                      onChange={(e) => handleChanges(e)}
                    >
                      <option value="">Select State</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="Arizona">Arizona</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Delaware">Delaware</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Maine">Maine</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Montana">Montana</option>
                      <option value="North Carolina<">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New York">New York</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Washington">Washington</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wyoming">Wyoming</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      State is required!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                      value={data.city}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="zip"
                      id="zip"
                      placeholder="Zip"
                      value={data.zip}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Zip is required!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>Parish</Form.Label>
                    <Form.Select
                      className="form-control minimal"
                      size="sm"
                      type="text"
                      name="parishId"
                      id="parishId"
                      placeholder="Parish"
                      value={data.parishId}
                      onChange={(e) => handleChanges(e)}
                    >
                      <option value="">Select Parish</option>
                      {parishList.map((item, index) => {
                        return <option value={item.id}>{item.parish}</option>;
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Parsih is required!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label className="d-block">
                      Anniversary Date (Month/Day)
                    </Form.Label>
                    <DatePicker
                      autoComplete="off"
                      dateFormat="MM-dd-yyyy"
                      placeholderText="mm-dd-yyyy"
                      selected={data && data.anniversary_date}
                      onChange={(e) => handleDatePicker(e)}
                      className="form-control"
                      name="anniversary_date"
                      id="anniversary_date"
                      maxDate={new Date()}
                      showDisabledMonthNavigation
                    />
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="notes"
                      id="notes"
                      placeholder="Notes"
                      value={data.notes}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Notes is required!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form.Label>
                      Do you or your spouse have any dietary allergies, dietary
                      restrictions or physical limitations?
                    </Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="allergies"
                      id="allergies"
                      value={data.allergies}
                      onChange={(e) => handleChanges(e)}
                    />
                  </Form.Group>
                </div>
                <div className="row mt-3">
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h4>Please provide at least one emergency contact:</h4>
                  </div>
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_name1"
                      id="emergency_name1"
                      placeholder="Name"
                      value={data.emergency_name1}
                      onChange={(e) => handleChanges(e)}
                    />
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <Form.Label>Relationship</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_relationship1"
                      id="emergency_relationship1"
                      placeholder="Relationship"
                      value={data.emergency_relationship1}
                      onChange={(e) => handleChanges(e)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_phone1"
                      id="emergency_phone1"
                      placeholder="Phone"
                      value={data.emergency_phone1}
                      onChange={(e) => handleChanges(e)}
                    />
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_name2"
                      id="emergency_name2"
                      placeholder="Name"
                      value={data.emergency_name2}
                      onChange={(e) => handleChanges(e)}
                    />
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <Form.Label>Relationship</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_relationship2"
                      id="emergency_relationship2"
                      placeholder="Relationship"
                      value={data.emergency_relationship2}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Relationship is required!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="emergency_phone2"
                      id="emergency_phone2"
                      placeholder="Phone"
                      value={data.emergency_phone2}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Phone is required!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row mt-3 ">
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h4>How did you hear about us?</h4>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between hear-options">
                    <label className="form-check-label ml-sm-2">
                      <input
                        type="checkbox"
                        className="form-check-input ageLabel"
                        name="Website"
                        value="Website"
                        onChange={handleCheckboxChange}
                        checked={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes("Website")
                        }
                        disabled={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes(data.hear_us === "Website")
                        }
                      />
                      Website
                      <i className="input-helper"></i>
                    </label>

                    <label className="form-check-label ml-sm-2">
                      <input
                        type="checkbox"
                        className="form-check-input ageLabel"
                        name="Church Bulletin"
                        value="Church Bulletin"
                        onChange={handleCheckboxChange}
                        checked={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes("Church Bulletin")
                        }
                        disabled={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes(
                            data.hear_us === "Church Bulletin"
                          )
                        }
                      />
                      Church Bulletin
                      <i className="input-helper"></i>
                    </label>

                    <label className="form-check-label ml-sm-2">
                      <input
                        type="checkbox"
                        className="form-check-input ageLabel"
                        name="Facebook"
                        value="Facebook"
                        onChange={handleCheckboxChange}
                        checked={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes("Facebook")
                        }
                        disabled={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes(data.hear_us === "Facebook")
                        }
                      />
                      Facebook
                      <i className="input-helper"></i>
                    </label>

                    <label className="form-check-label ml-sm-2">
                      <input
                        type="checkbox"
                        className="form-check-input ageLabel"
                        name="School Marquee"
                        value="School Marquee"
                        onChange={handleCheckboxChange}
                        checked={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes("School Marquee")
                        }
                        disabled={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes(
                            data.hear_us === "School Marquee"
                          )
                        }
                      />
                      School Marquee
                      <i className="input-helper"></i>
                    </label>

                    <label className="form-check-label ml-sm-2">
                      <input
                        type="checkbox"
                        className="form-check-input ageLabel"
                        name="Pulpit Talk/Table in Church"
                        value="Pulpit Talk/Table in Church"
                        onChange={handleCheckboxChange}
                        checked={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes("Pulpit Talk/Table in Church")
                        }
                        disabled={
                          data &&
                          data.hear_us &&
                          data.hear_us.includes(
                            data.hear_us === "Pulpit Talk/Table in Church"
                          )
                        }
                      />
                      Pulpit Talk/Table in Church
                      <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <Col lg={6} md={6} xs={12} sm={12} className=" mt-5">
                  <h4> Referral from a friend/Family member</h4>
                  <Form.Group className="form-group">
                    <Form.Control
                      // required
                      size="sm"
                      type="text"
                      name="referral_from_a_friend_or_family_member"
                      placeholder=" Referral from a friend/Family member"
                      id="referral_from_a_friend_or_family_member"
                      value={data.referral_from_a_friend_or_family_member}
                      className="form-control"
                      onChange={(e) => {
                        handleChanges(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Modal.Body>
              <Modal.Footer>
                {userData && userData.userRole === "SUBADMIN" ? null : (
                  <Button variant="primary" type="submit">
                    {ediUserIdClicked && ediUserIdClicked !== ""
                      ? "Update"
                      : "Submit"}
                  </Button>
                )}

                <Button
                  variant="secondary"
                  onClick={() => {
                    CloseCompose();
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          </Tab>
          <Tab eventKey="Retreats" title="Retreats" className="tab-content">
            <h4 className="card-title card_head mt-4 mb-3  ml-5">
              Total Retreats: {roasterCount}
            </h4>

            <div className="modal_table">
              <CustomDataTable
                data={roastersOfCoupleList}
                columns={columns1}
                pointerOnHover={true}
                highlightOnHover={true}
              />
            </div>
          </Tab>
        </Tabs>
      </Modal>
    </div>
  );
};

export default UpdateCouplePopUp;
